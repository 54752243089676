import React, { Component } from "react";
import {
  GoodCholesterolContainer,
  SmallBoxHeaderV1,
  BorderBottom,
  TopHeader,
  HealthCardContainer,
  HoverBackground,
} from "./styles";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import styled from "styled-components";
import {
  BloodPressureIcon,
  BodyMassIndexIcon,
  GlucoseIcon,
  HDLIcon,
  HemoglobinIcon,
  LDLIcon,
  ViewProfileIcon,
  WarningIcon,
  closeIcon,
} from "../../utils/icons";
import {
  CalendarHeader,
  CalendarWrapper,
  ConfirmVisitText,
  DividerLine,
  StyledModal,
  TableData,
  TableHeader,
} from "../PeopleHomeV2/ManageBuddies/styles";
import {
  commaSeperator
} from "../../utils/methods";
// import { BloodPressureIcon , HDLIcon, LDLIcon } from '../../utils/icons';
const SanitizedHeaderTitle = styled.div``;

export default class TopHealthData extends Component {
  constructor() {
    super();
    this.headerTitleRef = React.createRef();
    this.state = {
      onHover: -1,
      topHealthPopup: false,
      healthData: null,
    };
  }

  componentDidMount() {
    this.setSanitizedHeaderTitle();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.headerTitle !== this.props?.headerTitle) {
      this.setSanitizedHeaderTitle();
    }
  }

  setSanitizedHeaderTitle() {
    if (this.headerTitleRef.current) {
      const sanitizedHeaderTitle = DOMPurify.sanitize(this.props?.headerTitle);
      const fragment = document
        .createRange()
        .createContextualFragment(sanitizedHeaderTitle);
      while (this.headerTitleRef.current.firstChild) {
        this.headerTitleRef.current.removeChild(
          this.headerTitleRef.current.firstChild
        );
      }
      this.headerTitleRef.current.appendChild(fragment);
    }
  }

  showTopHealthPopup = (data) => {
    this.setState(
      {
        topHealthPopup: true,
        healthData: data,
      },
     
    );
  };

  closPopup = () => {
    this.setState({ topHealthPopup: false, healthData: null });
  };

  onHoverMouse = (id) => {
    this.setState({
      onHover: id,
    });
  };

  onLeaveHoverMouse = () => {
    this.setState({
      onHover: -1,
    });
  };


  //function for health risk card
  updateTopHealthRisksPayload = (data) => {
    // window.console.log("data for",data[0]?.name)
    const topCardsRisks = [
      {
        id: 1,
        name: data[0]?.name,
        background_color: "#F4AAA9",
        disease: data[0]?.disease,
        people: data[0]?.count || 0,
        icon: BodyMassIndexIcon(),
        cardName: "Top Health Risks",
        healthCostYear1:data[0]?.one_year_cost,
        healthCostYear3:data[0]?.three_year_cost,
      },
      {
        id: 2,
        name: data[1]?.name,
        background_color: "#F4AAA9",
        disease:  data[1]?.disease,
        people: data[1]?.count || 0,
        icon: GlucoseIcon(),
        cardName: "Top Health Risks",
        healthCostYear1: data[1]?.one_year_cost,
        healthCostYear3:data[1]?.three_year_cost,
      },
      {
        id: 3,
        name: data[2]?.name,
        background_color: "#F4AAA9",
        disease:  data[2]?.disease,
        people: data[2]?.count || 0,
        icon: HemoglobinIcon(),
        cardName: "Top Health Risks",
        healthCostYear1: data[2]?.one_year_cost,
        healthCostYear3:data[2]?.three_year_cost,
      },
    ];

    return topCardsRisks;
  };


  //function for health risk concerns
  updateTopHealthConcernsPayload = (data) => {
    const topCardsConcerns = [
      {
        id: 4,
        name: data[3]?.name,
        background_color: "#AF87C1",
        disease:  data[3]?.disease,
        people: data[3]?.count || 0,
        icon: LDLIcon(),
        cardName: "Top Health Concerns",
        healthCostYear1: data[3]?.one_year_cost,
        healthCostYear3:data[3]?.three_year_cost,
      },
      {
        id: 5,
        name: data[4]?.name,
        background_color: "#AF87C1",
        disease:  data[4]?.disease,
        people: data[4]?.count || 0,
        icon: HDLIcon(),
        cardName: "Top Health Concerns",
        healthCostYear1: data[4]?.one_year_cost,
        healthCostYear3:data[4]?.three_year_cost,
      },
      {
        id: 6,
        name: data[5]?.name,
        background_color: "#AF87C1",
        disease:  data[5]?.disease,
        people: data[5]?.count || 0,
        // people: data["Systolic Blood Pressure"]?.hypertensive_crisis_count || 0,
        icon: BloodPressureIcon(),
        cardName: "Top Health Concerns",
        healthCostYear1: data[5]?.one_year_cost,
        healthCostYear3:data[5]?.three_year_cost,
      },
    ];

    return topCardsConcerns;
  };

  render() {
    const { marginLeft, marginRight, childNumber, isTopRisks, payload } =
      this.props;
    const { onHover, topHealthPopup, healthData } = this.state;

    // window.console.log("updated payload is", payload);

    const updatedPayload = payload
      ? isTopRisks
        ? this.updateTopHealthRisksPayload(payload)
        : this.updateTopHealthConcernsPayload(payload)
      : [];

    // window.console.log("updated payload is", updatedPayload);
    // window.console.log(" healthData for the popup", healthData);

    return (
      <GoodCholesterolContainer
        isHeight={"390px"}
        marginLeft={marginLeft}
        marginRight={marginRight}
        childNumber={childNumber}
        isShadow={"none"}
      >
        <SmallBoxHeaderV1 isBackground="white">
          <SanitizedHeaderTitle ref={this.headerTitleRef} />
        </SmallBoxHeaderV1>
        <BorderBottom />
        <TopHeader>
          <div style={{ width: "310px" }}>Bio-marker</div>
          <div style={{ width: "130px" }}>Disease</div>
          <div style={{ width: "130px", textAlign: "end" }}>People</div>
        </TopHeader>

        {updatedPayload !== null &&
          updatedPayload.length > 0 &&
          updatedPayload?.map((item, index) => (
            item.people && item.people > 0 ?
              (<HealthCardContainer
                key={index}
                onMouseEnter={() => this.onHoverMouse(item?.id)}
                onMouseLeave={() => this.onLeaveHoverMouse()}
              >
                {item && item?.id && onHover === item?.id && (
                  <HoverBackground >
                    <div className="view-button"  onClick={() => this.showTopHealthPopup(item)}>
                      <div style={{ marginRight: "10px" }}>
                        <ViewProfileIcon />
                      </div>
                      {"View"}
                    </div>
                  </HoverBackground>
                )}
  
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <div
                      style={{
                        backgroundColor: item.background_color,
                        width: "60px",
                        height: "60px",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {item.icon}
                    </div>
                    <p
                      style={{
                        width: "220px",
                        color: "#005c87",
                        fontFamily: "rubik-medium",
                        fontSize: "16px",
                        marginBottom: 0,
                        alignItems: "center",
                      }}
                    >
                      {item.name}
                    </p>
                  </div>
                  <div className="data diseaseData">{item.disease}</div>
                  <div className="data peopleData">{item.people}</div>
                </div>
              </HealthCardContainer>)
              : null
          ))}

        {topHealthPopup && healthData  ? (
          <StyledModal show={topHealthPopup} width={"800px"} height={"492px"}>
            <CalendarHeader
              deleteInactiveUser={true}
              style={{
                background: "none",
                borderBottom: "1px solid rgba(0, 0, 0, 0.21)",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "10px" }}>{WarningIcon()}</div>
                <div>{healthData.cardName}</div>
              </div>
              <div  onClick={() => this.closPopup()} style={{display:"flex", justifyContent: "center",alignItems: "center",  cursor:"pointer",marginRight:'16px'}}>{closeIcon()}</div>
            </CalendarHeader>
            <CalendarWrapper padding="0 25px 25px 25px">
              {
                <ConfirmVisitText isTopData={true}>
                  <div  style={{
                    backgroundColor: healthData.background_color,
                    width: "80px",
                    height: "60px",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>{healthData.icon}</div>
                  <div style={{ textAlign: "left", marginLeft: "12px" ,fontSize:"18px", fontFamily:"rubik",alignItems:"center",marginTop:"5px"}}>
                    A high {healthData.name} of over 30 is impacting<span style={{fontFamily:'rubik-medium'}}> {healthData.people} people </span>in your
                    organization. Below are the costs associate with this risk:
                  </div>
                </ConfirmVisitText>
              }
              <DividerLine></DividerLine>
              <TableHeader>
                <div style={{width:'45%'}}>Cost Per Person</div>
                <div style={{width:'20%', display:"flex",justifyContent:"center"}}>People</div>
                <div style={{width:'35%',display:"flex",justifyContent:"flex-end"}}>Total Health Costs</div>
              </TableHeader>
              <DividerLine></DividerLine>
              <TableData>
                <div style={{width:'45%'}} >Year 1: ${commaSeperator(Math.trunc(healthData.healthCostYear1))}</div>
                <div style={{width:'20%',display:"flex",justifyContent:"center"}}>{healthData.people}</div>
                <div style={{fontFamily:'rubik-medium', width:'35%',display:"flex",justifyContent:"flex-end"}}>${Math.floor(healthData.healthCostYear1 * healthData.people).toLocaleString()}</div>
              </TableData>
              <DividerLine></DividerLine>
              <TableData>
                <div style={{width:'45%'}}>Year 3: ${commaSeperator(Math.trunc(healthData.healthCostYear3))}</div>
                <div style={{width:'20%',display:"flex",justifyContent:"center"}}>{healthData.people}</div>
                <div style={{fontFamily:'rubik-medium', width:'35%',display:"flex",justifyContent:"flex-end"}}>${Math.floor(healthData.healthCostYear3 * healthData.people).toLocaleString()}</div>
              </TableData>
              <DividerLine></DividerLine>
            </CalendarWrapper>
          </StyledModal>
        ): null}
      </GoodCholesterolContainer>
    );
  }
}

TopHealthData.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  marginLeft: PropTypes.string.isRequired,
  marginRight: PropTypes.string.isRequired,
  childNumber: PropTypes.string.isRequired,
  payload: PropTypes.array.isRequired,
  chartData: PropTypes.object.isRequired,
  isTopRisks: PropTypes.bool,
};
